import React from 'react';
import PropTypes from 'prop-types'
// import WOW from 'wow.js';


    class AnimateComponent extends React.Component {
 
      componentDidMount() {
        if (typeof window !== 'undefined') { 
          let WOW = require('../../node_modules/wow.js/dist/wow');
          const wow = new WOW(); wow.init();
      }
    }

      render() {
        const CustomTag = this.props.tag ? this.props.tag : 'div';   
        return(
          <CustomTag className={this.props.styleName} id={this.props.id}>
            {this.props.children}
          </CustomTag>
        )
       }
    }

    AnimateComponent.propTypes = {
      tag: PropTypes.string,
      styleName: PropTypes.string,
      id: PropTypes.string,
      children: PropTypes.node.isRequired
    }
  
  

  export default AnimateComponent