import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import {graphql} from "gatsby"

import AnimateComponent from '../components/animate-component'


class NewsTemplate extends React.Component {

    render () {

    const post = this.props.data.allWordpressWpAktualnosci.edges[0].node;

        return (
            <Layout>
                <SEO title={`${post.title} | Świecki Kancelaria`} />
                <AnimateComponent
                    styleName=""
                >
                    <div className="post__container">
                            <p className="specialization-details__back-arrow" onClick={()=> window.history.back()}>
                                Powrót
                            </p>
                        <header>
                            <div aos='fade-up' className=' wow fadeInUp' delay='0'>

                                <h1 className='post__title' dangerouslySetInnerHTML={{ __html: post.title }} />


                                  {post.content !== '' &&
                                    <div className="p_normal post__text" dangerouslySetInnerHTML={{ __html: `${post.content}` }} />
                                }

                            </div>
                        </header>

                    </div>

                </AnimateComponent>

            </Layout>
        )
    }
}

export default NewsTemplate
export const pageQuery = graphql `
query($id: String!) {
    allWordpressWpAktualnosci( filter: { id: {eq : $id}}){
        edges {
          node {
            title
            content


          }

        }
      }

}
`